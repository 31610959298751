<template>
  <div class="returns-logistics">
    <div class="l-container" v-loading="loading">
      <!-- 退货退款 -->
      <ul class="l-both">
        <li class="h-item">
          <div class="h-num">1</div>
          <span class="h-text">买家申请退货退款</span>
        </li>
        <li class="h-item">
          <div class="h-num">2</div>
          <span class="h-text"> 卖家处理退货申请</span>
        </li>
        <li class="h-item">
          <div class="h-num">3</div>
          <span class="h-text"> 买家退货</span>
        </li>
        <li class="h-item h-current">
          <div class="h-num">4</div>
          <span class="h-text">退款完毕</span>
        </li>
      </ul>
      <div class="l-content">
        <!-- 退货物流单号详情 -->
        <div class="c-logistics">
          <div class="g-content">
            <div class="c-img">
               <img   :src="Goods.img"/>
            </div>
            <div class="c-text">
              <li>
                {{ Goods.goods_name }}
              </li>
              <li class="t-middle">
               &nbsp;
              </li>
              <li class="t-price">
                <strong>{{ info.refundsum }}</strong>
              </li>
            </div>
          </div>
          <!-- form表单 -->
          <el-form
            class="r-container"
            label-width="80px"
            :model="logisticsForm"
          >
            <!-- 物流单号 -->
            <el-form-item label="物流单号:">
              <el-input
                v-model="logisticsForm.refundSum"
                @input="fromChangeRefundSum"
                placeholder="填写物流单号"
                size="small"
              ></el-input>
              <div class="el-form-item__error">{{summes}}</div>
            </el-form-item>
            <!--物流公司-->
            <el-form-item label="物流公司:">
              <el-select
                v-model="logisticsForm.refundValue"
                @change="fromChangeRefundchange"
                placeholder="请选择"
                size="small"
                filterable
              >
              <el-option v-for="item in options" :key="item.express_id" :label="item.express_name" :value="item.express_id">
              </el-option>
              </el-select>
              <div class="el-form-item__error">{{rmes}}</div>
            </el-form-item>
            <!-- 联系电话 -->
            <el-form-item label="联系电话:" class="r-price">
              <el-input
                v-model="logisticsForm.phone"
                @input="fromChangePhone"
                size="small"
                placeholder="请输入联系电话"
              ></el-input>
              <div class="el-form-item__error">{{mes}}</div>
            </el-form-item>
            <!-- 退款说明-->
            <el-form-item label="退货说明:">
              <el-input
                type="textarea"
                maxlength="200"
                placeholder="请输入退货说明"
                v-model="logisticsForm.textarea"
              ></el-input>
            </el-form-item>
            <!-- 图片上传-->
            <el-form-item label="上传图片:" class="r-upload">
              <el-upload
                ref="pictureUpload" 
                list-type="picture-card"
                :file-list="fileList" 
                multiple  
                :auto-upload="false"
                limt=3
                action="#"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img class="el-upload-list__item-thumbnail" :src="file.url"/>
                  <span class="el-upload-list__item-actions">
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
            <!-- 提交 -->
            <el-form-item>
              <el-button @click="submitUp()">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 商品详情 -->
        <div class="c-goods">
          <div class="g-title">订单详情</div>
          <div class="g-content">
            <div class="g-img">
              <img :src="Goods.img"/>
            </div>
            <div class="g-goodsName">
              <div>{{ Goods.goods_name }}</div>
              <div class="g-spec">
                &nbsp;
              </div>
            </div>
          </div>
          <div class="g-order">
            <ul class="o-title">
              <li>卖家:</li>
              <li>订单编号:</li>
              <li>成交时间:</li>
              <li>单价:</li>
              <li>邮费:</li>
              <li>商品总价:</li>
            </ul>
            <ul class="o-data">
              <li>{{ info.store_name }}</li>
              <li class="o-item"  v-if="info.Order">{{ info.Order.order_no }}</li>
              <li v-if="info.Order">{{ info.Order.delivery_time }}</li>
              <li>￥{{ Goods.goods_price }}*{{ Goods.goods_num }}(数量)</li>
              <li>￥0.00</li>
              <li>￥{{ Goods.goods_price * Goods.goods_num }}</li>
            </ul>
          </div>
          <div class="g-refund">
            <ul class="r-title">
              <li>退款编号：</li>
              <li>退款金额：</li>
              <li>申请件数</li>
              <li>原因:</li>
              <li>要求:</li>
            </ul>
            <ul class="r-data">
              <li>{{ info.order_no }}</li>
              <li>￥{{ info.refundsum }}</li>
              <li>{{ Goods.goods_num }}</li>
              <li v-if="info.type == 1">{{ info.reason | refundOptions }}</li>
              <li v-if="info.type == 2">{{ info.reason | bothOptions }}</li>
              <li>{{ info.type == 1 ? '仅退款':'退货退款' }}</li>
            </ul>
          </div>
          <div class="g-title">退货地址信息</div>
          <div class="g-Logistics">
            <ul class="l-title">
              <li>收货人:</li>
              <li>联系电话:</li>
              <li>收货地址:</li>
            </ul>
            <ul class="l-data">
              <li>{{ addr.username }}&nbsp;</li>
              <li>{{ addr.phone }}&nbsp;</li>
              <li class="d-address">{{ addr.address }}&nbsp;</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AfterLogistics,LogisticsUp } from '@/api/order'
export default {
  name: "returnsLogistics",
  data() {
    return {
      id:0,
      info:[],
      addr:[],
      Goods:[],
      loading:false,
      fileList: [],
      refundActive: 3,
      logisticsForm: {
        refundSum: "",
        refundValue:"",
        textarea:"",
        phone:""
      },
      options: [],
      disabled:false,
      mes:'',
      summes:'',
      rmes:''
    }
  },
  methods: {
    async submitUp(){
      if(!this.fromChangeRefundSum()) return false;
      if(!this.fromChangeRefundchange()) return false; 
      if(!this.fromChangePhone()) return false;
      
       let i = 1;
      let formData = new FormData();
      let uploadFiles = this.$refs.pictureUpload.uploadFiles;
   
      formData.append("id",this.id);                     // ID
      formData.append("phone",this.logisticsForm.phone); // 联系人手机号码
      formData.append("refundSum",this.logisticsForm.refundSum);     // 物流号
      formData.append("refundValue",this.logisticsForm.refundValue); // 物流公司
      formData.append("textarea",this.logisticsForm.textarea);       // 退货说明
      uploadFiles.forEach(item =>{formData.append("file"+i, item.raw);i++;})
      const {data: res} = await LogisticsUp(formData);
      if (res.message == 'ok') {
        this.$router.push('/user/refund/details?id='+this.id);
      }
    },
    async RefundInfo(){
      this.loading = true;
      const { data: res } = await AfterLogistics({id:this.id});
      if (res.message == 'ok') {
        this.info    = res.result.info.info;
        this.Goods   = this.info.Goods;
        this.addr    = res.result.info.addrlist;
        this.options = res.result.expresslist;
      }else this.$router.push("/home/error");
      this.loading = false;
    },
    handleRemove(file) { // 相片墙图片删除
      let uploadFiles = this.$refs.pictureUpload.uploadFiles
      for (var i = 0; i < uploadFiles.length; i++) {
        if (uploadFiles[i]['url'] == file.url) {
          uploadFiles.splice(i, 1)
        }
      }
	  },
    fromChangePhone(){ // 退款金额验证
      let test=/^[1][3,4,5,7,8][0-9]{9}$/;
      if(this.logisticsForm.phone==''){
        this.mes="请输入收货人手机号码";
        return false;
      }
      if(!(test.exec(this.logisticsForm.phone)) ){
        this.mes="请输入正确的手机号码";
        return false;
      }
      this.mes="";
      return true;
    },
    fromChangeRefundSum(){ // 物流单号验证
      this.summes="";
      let test=/^[0-9a-zA-Z.]{0,}$/;
      if(this.logisticsForm.refundSum==''){
        this.summes="请输入物流单号";
        return false;
      }
      if(!(test.exec(this.logisticsForm.refundSum)) ){
        this.summes="请输入正确的物流单号";
        return false;
      }
      this.summes="";
      return true;
    },
    fromChangeRefundchange(){
      this.rmes = "";
      if(this.logisticsForm.refundValue==""){this.rmes = "请选择物流公司"; return false;} 
      this.rmes="";
      return true;
    }
  },
  created() {
    this.id = this.$route.query.id;
    if (typeof this.id !== 'number' && isNaN(this.id)) { this.$router.push("/home/error"); return false; }
    this.RefundInfo();
  },
  filters: {
    refundOptions(val) {
      if (val == 1) return '不喜欢/不想要';
      if (val == 2) return '空包裹';
      if (val == 3) return '未按约定时间发货';
      if (val == 4) return '快递/物流一直未送到';
      if (val == 5) return '快递/物流无跟踪记录';
      if (val == 6) return '货物破损已拒签';
    },
    bothOptions(val) {
      if (val == 1) return '退运费';
      if (val == 2) return '大小/尺寸与商品描述不符';
      if (val == 3) return '颜色/型号/款式与商品描述不符';
      if (val == 4) return '材质与商品描述不符';
      if (val == 5) return '做工粗糙/有瑕疵';
      if (val == 6) return '质量问题';
      if (val == 7) return '配件问题';
      if (val == 8) return '少件/漏发';
      if (val == 9) return '包装/商品破损';
      if (val == 10) return '卖家发错货';
    },
  }
}
</script>

<style lang="less" scoped>
.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 148px;
  height: 148px;
}
.returns-logistics {
  .l-container {
    width: 1000px;
    margin: 0 auto;

    .l-both {
      display: flex;
      justify-content: space-between;
      margin: 20px 0px;

      .h-item {
        width: 230px;
        height: 38px;
        background: red;
        display: flex;
        justify-content: center;
        align-items: center;

        .h-num {
          width: 20px;
          height: 20px;
          border: 1px solid red;
          border-radius: 50%;
          background: #fff;
          text-align: center;
          line-height: 20px;
          font-weight: 600;
          color: red;
          margin-right: 5px;
        }

        .h-text {
          font-size: 14px;
          color: #fff;
        }
      }

      .h-current {
        background: #ccc;

        .h-num {
          border: 1px solid #ccc;
          color: #ccc;
        }
      }
    }

    .l-content {
      border: 2px solid #ddd;
      min-height: 500px;
      margin-bottom: 20px;
      display: flex;

      .c-logistics {
        width: 700px;
        padding: 20px;
        border-right: 2px solid #ddd;
        min-height: 500px;
        font-size: 14px;

        .g-content {
          height: 76px;
          display: flex;
          font-size: 12px;

          .c-img {
            margin-right: 10px;

            img {
              width: 76px;
              height: 76px;
            }
          }

          .c-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 13px;

            .t-middle {
              color: #666;
            }

            .t-price {
              strong {
                color: #e1251b;
              }
            }
          }
        }

        .r-container {
          margin-top: 20px;

          .el-input {
            width: 60%;
          }
          .el-select {
            width: 60%;
          }
          .el-textarea {
            width: 60%;
          }

          .el-button {
            width: 25%;
            background: #e1251b;
            color: #fff;
          }
        }
      }

      .c-goods {
        width: 300px;
        padding: 20px;

        .g-title {
          line-height: 40px;
          font-size: 16px;
          border-bottom: 1px solid #ddd;
        }

        .g-content {
          height: 140px;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;

          .g-img {
            width: 54px;
            height: 54px;

            img {
              width: 54px;
              height: 54px;
            }
          }

          .g-goodsName {
            padding-left: 10px;

            .g-spec {
              margin-top: 5px;
              color: #666;
            }
          }
        }

        .g-order {
          padding: 10px 0px;
          display: flex;
          font-size: 13px;
          border-bottom: 1px solid #ddd;

          .o-title {
            margin-right: 20px;

            li {
              color: #666;
            }
          }

          .o-data {
            .o-item {
              color: #0088cb;
            }
          }
        }

        .g-refund {
          padding: 10px 0px;
          display: flex;
          font-size: 13px;

          .r-title {
            margin-right: 20px;

            li {
              color: #666;
            }
          }
        }

        .g-Logistics {
          padding: 10px 0px;
          font-size: 13px;
          display: flex;

          .l-title {
            margin-right: 20px;

            li {
              color: #666;
              width: 60px;
            }
          }
        }
      }
    }
  }
}
</style>